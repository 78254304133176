* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --top-inset-area: env(safe-area-inset-top);
  --bottom-inset-area: env(safe-area-inset-bottom);
}

html, body, #root, .app {
  height: 100%;
}

body {
  font-family: -apple-system, "Segoe UI", Roboto, Ubuntu, "SF Pro Text", system-ui, "Helvetica Neue", sans-serif;
  overflow: hidden;
  background: #fff;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.app-border {
  position: fixed;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid #000;
  z-index: 10000;
  pointer-events: none;
}

.web-runtime-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 74px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
  display: flex;
  padding: 4px 20px 0;
  flex-direction: row;
  align-items: center;
  direction: rtl;
  background-color: white;
}

.preview-proton-attribution {
  display: flex;
  align-items: center;
  justify-content: center;
  direction: ltr;
  text-align: center;
  text-decoration: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 24px;
  color: black;
  transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

.right{
  direction: rtl;
}

.preview-proton-attribution:hover {
  background-color: rgba(0, 168, 152, 1);
  border-color: rgba(0, 168, 152, 1);
  color: white;
}

.preview-proton-attribution h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.preview-proton-attribution .logo {
  display: inline-block;
  background-image: url('./adalo-circle-logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 45px;
  width: 72px;
  margin-left: -20px;
}

.details-footer-clone-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 15px;
  margin-left: 10px;
}

.details-footer-clone-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  font: inherit;
  color: #333333;
  background: #FFC00E;
  text-decoration: none;
  text-transform: uppercase;
  /*appearance: none;*/
  position: relative;
  outline: none;
  padding: 0 25px;
  border-radius: 50px;
  transition: all 0.2s;
  justify-content: center;
  border: 1px solid #FFC00E;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.details-footer-clone-button:hover{
  background: #FFAD00;
}

